import { useQuery } from 'react-query'
import axios from 'axios'
import { getEventsAPIUrl } from '../utils/apiUtils'
import { useGeo } from './queries'
import { eventData } from './eventData'
import sortBy from 'lodash-es/sortBy'

const ishaTicketButtonLabel = 'Buy Event + Book'

export function isDefaultIshaTicketing(buttons) {
  return (
    buttons.length === 1 &&
    buttons[0].buttonLink.indexOf('/karma/ticketing') !== -1 &&
    ['get tickets', 'buy tickets', ishaTicketButtonLabel.toLowerCase()].indexOf(
      buttons[0].buttonText.toLowerCase()
    ) !== -1
  )
}

export function enhanceEventsData(data) {
  let enhanced = data.map((event) => {
    const datum = eventData[event.eventId] || {}
    let newButtons = event.buttons
    let bookstore

    if (isDefaultIshaTicketing(event.buttons)) {
      newButtons = [
        {
          ...event.buttons[0],
          ...(datum.price && { price: datum.price }),
          buttonText: ishaTicketButtonLabel,
          buttonLink: event.buttons[0].buttonLink + '#tickets',
        },
      ]
    } else if (event.buttons.length === 1) {
      bookstore = event.buttons[0].buttonText
      newButtons = [
        {
          ...event.buttons[0],
          ...(datum.price && { price: datum.price }),
          buttonText: ishaTicketButtonLabel,
          buttonLink: event.buttons[0].buttonLink + '#tickets',
        },
      ]
    }

    return {
      ...event,
      ...(datum.subText && { subText: datum.subText }),
      buttons: newButtons,
      duration: datum.duration,
      isPast: !!datum.isPast,
      bookstore,
      featured: !!datum.featured,
    }
  })

  enhanced = sortBy(enhanced, [(event) => (event.featured ? 0 : 1), 'datetime'])

  return {
    current: enhanced.filter((event) => !event.isPast),
    past: enhanced.filter((event) => event.isPast),
  }
}

export function useEvents() {
  const {
    geo: { state, country },
  } = useGeo()

  return useQuery(
    'events',
    () =>
      axios
        .post(getEventsAPIUrl(), {
          state: state,
          country: country,
          action: 'get-events',
        })
        .then((res) => enhanceEventsData(res.data)),
    {
      staleTime: 1000 * 60 * 5,
      enabled: Boolean(state && country),
    }
  )
}
