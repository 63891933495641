import React from 'react'
import { Text } from '@chakra-ui/react'

export default function TextContainer({ spacing = '1em', ...props }) {
  return (
    <Text
      as={'div'}
      sx={{
        ...(spacing && {
          '& > * + *': {
            marginTop: spacing,
          },
        }),
        'ul, ol': {
          paddingInlineStart: '2em',
        },
      }}
      {...props}
    />
  )
}
